// noinspection ES6UnusedImports
import React from 'react';
import {createRoot} from 'react-dom/client';
import NitroTracker from '@personalization/scripts/components/NitroTracker.jsx';
import JshModule from "@core/scripts/helper/jsh-module";

const {moduleScope, config} = JshModule('personalization/nitro-tracker');

createRoot(moduleScope).render(
    <NitroTracker
        nitroOptIn={config.nitroOptIn}
        nitroEcId={config.nitroEcId}
        nitroEnvironmentName={config.nitroEnvironmentName}
        trackingEnvironment={config.trackingEnvironment}
        pageType={config.pageType}
        pageViewId={config.pageViewId}
    />
);
